.App {
  margin: 0;
  position: relative;
  /*Darker - #010f13*/
  /* background-color:  #011317; */
  /* background-color:  #1a535c; */
  --backgroundColor: #253031;
  --backgroundShadow: #1f292a;
  --backgroundForeshadow: #2b3738;         
  --yellow:#ddae3f;
  background-color:  var(--backgroundColor);
}

.App::-webkit-scrollbar{
  display: none;
}

/* The side navigation menu */
#NavBar{
  height: fit-content; /* 100% Full-height */
  width: 200px; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  background-color: #49302bdc;
  border-radius: 10px 0 0 10px;
  z-index: 5; /* Stay on top */
  top: 30%; /* Stay at the top */
  right: -190px;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  padding-top: 10px;
  padding-bottom: 10px;
}

/* The navigation menu links */
#NavBar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 24px;
  font-weight: 100;
  color: beige;
  display: block;
  transition: 0.3s;
}

#NavBar a:hover {
  color: var(--yellow);
  background-color: rgba(0, 0, 0, 0.4);
}

#NavBar a:active {
  font-weight: 900;
  color: var(--yellow);
}

/* The side navigation menu */
#NavBar:hover{
  right: 0;
  background-color: #2a211f;
}

#NavBar:hover > .App{
  background-color: rgba(0, 0, 0, 0.4);
}

h1{
  color:beige;
  font-size:3em;
}

h2 {
  color: var(--yellow);
  padding:0 0 0 0px;
  font-size:2.25em;
}

h3 {
  color: var(--yellow);
  font-size:1.75em;
}

h4 {
  color:beige;
  font-size:1.125em;
}

h5 {
  color:beige;
  padding:0 0 0 40px;
}

p {
  font-size: 18px;
  color:beige;
}

ul {
  font-size: 18px;
  color:beige;
  padding: 10px 10px 0 0;
  margin:0;
}

ul > li > span {
  font-weight: 300;
}

input[type=text]{
  outline: none;
  border-radius: 0;
  box-shadow: none;
  height: 25px;
  font-size: 14px;
  position: relative;
  transition: border .9s ease;
  padding: 1rem;
  color: var(--yellow);
  border: none;
  border-radius: 30px;
  background: var(--backgroundColor);
  box-shadow: inset 5px 5px 10px var(--backgroundShadow),
              inset -5px -5px 10px var(--backgroundForeshadow);
}

input[type=email]{
  outline: none;
  border-radius: 0;
  box-shadow: none;
  height: 25px;
  font-size: 14px;
  position: relative;
  transition: border .9s ease;
  padding: 1rem;
  color: var(--yellow);
  border: none;
  border-radius: 30px;
  background: var(--backgroundColor);
  box-shadow: inset 5px 5px 10px var(--backgroundShadow),
              inset -5px -5px 10px var(--backgroundForeshadow);
}

#additional-skills-container{
  margin-top: 30px;
  font-size: 14px;
  padding: 1rem;
  color: var(--yellow);
  border-radius: 30px;
  background: var(--backgroundColor);
  box-shadow: inset 10px 10px 11px #1f292a, inset -10px -10px 11px #2b3738
}

#additional-skills-container > h3{
  text-align: center;
  margin: 10px 0px 10px 0;
}
#additional-skills-container> ul{
  list-style-type:none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0 10px;
  padding: 0;
  /* gap: 15px 10px; */
}

#additional-skills-container > ul > li{
  padding: 10px;
  width: fit-content;
}

/* input[type=text]:focus {
  outline: none;
} */

textarea{
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  height: 44px;
  font-size: 14px;
  position: relative;
  transition: border .9s ease;
  padding: 1rem;
  color: var(--yellow);
  border: none;
  min-height: 175px;
  resize: none;
  border-radius: 30px;
  background: var(--backgroundColor);
  box-shadow: inset 5px 5px 10px var(--backgroundShadow),
              inset -5px -5px 10px var(--backgroundForeshadow);
}

textarea:focus {
  outline: none;
}

input[type="submit"]{
  background-color: var(--yellow);
  margin-top: 20px;
  margin-left: 5px;
  height: 50px;
  border-radius: 1rem;
  border: none;
  padding: 10px 30px;
  transition: all 0.3s ease;
  font-size: 16px;
  display: inline-block;
  outline: none;
  cursor: pointer;
}

.Particles {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width:100%;
  z-index: 0;
}

.Particles canvas {
  transition: 0.2s all ease-in-out;
  height: 100vh;
  width: 100%;
}


#HeroContainer {
  text-align: center;
  height:100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(45.72% 45.72% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 120%), url("/src/images/mohamed-nohassi-odxB5oIG_iA-unsplash____.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

#heroText {
  z-index: 1;
  border-radius: 10px;
  padding: 20px;
  background-color: rgb(0,0,0,0.65);
  box-shadow: 2px;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -webkit-touch-callout : none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none; 
  /* background:#ffff22 */
}

/*All containers in body element MUST be have container id */
#container {
  padding:50px 100px 50px 100px;
}

#skillText_span{
  display:flex; 
  width:100%; 
  justify-content:space-between;
}

#skillText_span > p{
  margin-bottom: 5px;
}

#skills_list {
  display: flex;
  flex-wrap:wrap;
  column-gap:30px;
  row-gap:20px;
  margin-bottom: 10px;
}

#skills_item{
  flex:1;
  flex-basis: calc(47% - 10px);
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}

#skills_list >span{
  flex-basis: calc(50% - 10px);
}


#loadingBar {
    height: 10px; 
    position: relative;
    background: var(--backgroundColor);
    border-radius: 25px;
    padding: 1px;
    box-shadow:inset 8px 8px 8px var(--backgroundShadow), inset -8px -8px 8px var(--backgroundForeshadow)
}

#loadingBar > span {
  display: block;
  height: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: var(--yellow);
  position: relative;
  overflow: hidden;
}

#about_Me { 
  z-index: 2;
  max-width: 80%;
  margin: auto;
  display: grid;
  grid-template-areas: 
  "img title title"
  "img info info";
  grid-template-columns: 1fr 2fr;
  margin-top: 50px;
  padding: 10px;
  border-radius: 20px; 
  box-shadow:  15px 15px 20px var(--backgroundShadow),
  -15px -15px 20px var(--backgroundForeshadow);
}

#aboutMe_text_content{
  grid-area: info;
}
#aboutMe_ul_div{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

#aboutMe_ul {
  list-style-type:none;
}

#aboutMe_ul > li {
  padding:  10px 0 10px 0px;
  width: inline-flex;
}

#aboutMe_imageContainer {
  width: 97%;
  height: 250px;
  display: grid;
  grid-area: img;
  grid-template-columns: 1fr;
  align-items: center;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -webkit-touch-callout : none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

#aboutMe_Img {
  width: 100%;
  max-height: 290px;
  border-radius: 10px;
  object-fit:cover;
}

#career-ul {
  padding-top: 10px; 
}

#career-li {
  display:flex;
  padding: 30px; 
  justify-content:space-between;
  border-radius: 30px;
  background: var(--backgroundColor);
  box-shadow: inset 6px 6px 12px var(--backgroundShadow),
              inset -6px -6px 12px var(--backgroundForeshadow);
}

#career-li-company {
  text-align: center;
  padding:20px;
  border-radius: 30px;
  width: 100%;
  max-width: 200px;
  background: var(--backgroundColor);
  box-shadow:  6px 6px 12px var(--backgroundShadow),
               -6px -6px 12px var(--backgroundForeshadow);
}

#career-li-company > #job-title {
  color: white;
  font-size: 18px;
  padding: 0 5% 0 5%;
  font-weight: normal;
} 

#career-li-company > p {
  margin:0;
  font-size: 14px;
  text-align: center;
  font-weight: 300;
}

#career-li-dets {
  padding-left: 50px;
} 

#career-li-dets > p {
  margin:0;
  padding: 20px;
  font-weight: 300;
  max-width: 750px;
} 

#careerContainer{
  padding: 20px 10px 20px 20px;
  border-radius: 50px;
  background: var(--backgroundColor);
  box-shadow:  11px 11px 18px var(--backgroundShadow),
               -11px -11px 18px var(--backgroundForeshadow);
}

#contact_form{
  display: grid;
  line-height: 1.5;
  gap: 5px 15px;
  grid-template-rows: [row1-start] 1fr [row1-end row2-start] 1fr [row2-end row3-start] 3fr [row3-end];
  grid-template-columns: none;
  grid-template-areas:
      "name email"
      "subject subject"
      "message message";  
}

#form_submit{
  width: fit-content;
}

#form_submit:hover{
  background-color: #dbb563;
  box-shadow: 2px 2px 10px #161c1d;
}

#form_submit:active{
  background-color: var(--yellow);
  box-shadow: none;
}

#CV_container{
  margin-top: 50px;
  /* padding: 20px 300px 30px 300px; */
  color:rgba(190, 111, 111, 0.8);
  background-color: #967731;
  box-shadow: inset 0px -2px 10px 0px #462f01 , inset 0px 2px 10px 0px #462f01;
}

#CV_subcontainer{
  max-width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 20px 10px;
  gap: 20px;
}

#CV_container p, #CV_container h2{
  display: grid;
  justify-content: left;
  margin: 0px;
  color: var(--backgroundShadow);
}

#CV_container p{
  color:#534219;
}

#button_container{
  display: grid;
  justify-content: right;
  align-items: center;
  padding: 5px;
}

#button_container button{
  width: 150px;
  height: 60px;
  border-radius: 10px;
  background: #967731;
  box-shadow:  4px 4px 15px #695322, -4px -4px 15px #c39b40;
  border: none;
  cursor: pointer;
}

#button_container button:hover {
  box-shadow:  4px 4px 15px #3c3014, -4px -4px 15px #f0be4e;
}

#button_container button:active {
  box-shadow: inset 4px 4px 15px #3c3014,
            inset -4px -4px 15px #f0be4e;
}

#button_container button svg{
  color: #534219;
}

#button_container button:hover svg{
  color: #d1ad58;
}

#education_div{
  padding:30px;
  display: flex;  
  justify-content: space-between; 
  gap: 30px;
  overflow-x: auto; /* Enables horizontal scrolling */
  scrollbar-width: 'thin'; /* Makes the scrollbar thin */
  /*scrollbar-color: dark; /* Optional: styles the scrollbar */
}
/* Styling for scrollbar for webkit browsers */
#education_div::-webkit-scrollbar {
  height: 10px;
}
#education_div::-webkit-scrollbar-thumb:hover {
  background: #e0b348;
}

#education_div::-webkit-scrollbar-track {
  /* background: var(--backgroundShadow); */
  border-radius: 10px;
  box-shadow: inset 5px 5px 10px #1b2324,
            inset -5px -5px 10px #2f3d3e;
}

#education_div::-webkit-scrollbar-thumb {
  background: #8c702d;
  border-radius: 10px;
}

#education_tile {
  display:flex;
  flex-direction: column;
  flex: 0 0 auto;
  padding:10px;
  justify-content: space-between;
  width:400px;
  height: auto;
  border-radius: 18px;
  background: var(--backgroundColor);
  box-shadow:  11px 11px 16px var(--backgroundShadow),
               -11px -11px 16px var(--backgroundForeshadow);
}

#educationTile_content{
  display:flex; 
  flex-direction:column; 
  justify-content:center; 
  color:#011317;
}

#educationTile_imgDiv{
  display:flex; 
  justify-content:center; 
  color:#011317;
  height:100%;
  align-content:center; 
  padding:10px; 
  border-radius: 10px;
  background: #8c702d;
  box-shadow: inset 5px 5px 15px #382d12,
              inset -5px -5px 15px #e0b348;

  /* background: #849a9f;
  box-shadow: inset 5px 5px 7px #4f5c5f,
              inset -5px -5px 7px #b9d8df; */
}


#social_icons{
  display: inline-flex;
  gap: 20px;
}

/* unvisited link */
#social_icons svg {
  color: beige;
  padding: 2px;
  background: var(--backgroundColor);
  border-radius: 5px;
  box-shadow:  3px 3px 10px #161c1d,
  -3px -3px 10px #344445;
}

#social_icons svg:hover {
  color: beige;
  color: var(--yellow);
  box-shadow:  5px 5px 10px #0f1314,
             -5px -5px 10px #3b4d4e;
}

#social_icons svg:active {
  color: beige;
  box-shadow: inset 5px 5px 10px #1e2627,
  inset -5px -5px 10px #2c3a3b;
}


/* unvisited link */
#social_icons a:link {
  color: beige;
}

/* visited link */
#social_icons a:visited {
  color: beige;
}

/* mouse over link */
#social_icons a:hover {
  color: var(--yellow);
}

/* selected link */
#social_icons a:active {
  color: blue;
}




@media screen and (min-width: 1200px){
  body {
    margin:auto;
  }

  #container {
    max-width: 1140px;
    margin:auto;
  }
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 800px) {
  #container {
    padding:45px 80px 45px 80px;
  }

  #about_Me { 
    grid-template-areas: 
    "img title title title title"
    "info info info info info";
    margin-top: 50px;
    padding: 10px;
    border-radius: 20px; 
  }
  #aboutMe_imageContainer {
    height: 60%;
  }

  #aboutMe_ul_div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 640px) {
  #container {
    padding:25px 50px 25px 50px;
  }

  #CV_subcontainer, #CV_subcontainer p{
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  #about_Me { 
    max-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 50px;
    padding: 10px;
    border-radius: 20px; 
  }
  #aboutMe_imageContainer {
    width:100%;
    height: 30%;
  }

  #aboutMe_text_content{
    padding: 0 10px 0 10px;
  }
  #about_Me h4, #about_Me h2{
    text-align: center;
  }
  
  #aboutMe_ul_div{
    display: flex;
    flex-direction: column;
  }

  #career-li {
    flex-wrap: wrap;
    justify-items: center;
  }

  #career-li-company {
    max-width: 75%;
    margin:auto;
    margin-top: 10px;
  }
  
  #career-li-dets {
    padding-left: 0px;
  }
  
  #education_tile{
    width: 80%;
  }

  #education_div{
    flex-direction: column;
    align-items: left;
    gap: 50px;
  }

  #contact_form{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  #skills_list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  #skills_list > span{
    width:100%;
  }

  #career-ul {
    padding: 0px; 
  }
  
  #career-li {
    padding: 15px;
    gap: 10px; 
  }
  
  #career-li-company {
    padding:10px;
  }
  
  #career-li-dets {
    padding-left: 0px;
  } 
  
  #career-li-dets > p {
    width: 80%;
    text-align: center;
    margin: auto;
  }
  
  #careerContainer{
    padding: 10px 10px 10px 10px;
  }

}

@media (max-width: 375px){
  /* The side navigation menu */
  #NavBar{
    right: -100%;
    z-index: -5;
  }
  h1, h2 {
    text-align: center;
  }
  
  #heroText h1{
    font-size: 32px;
  }

  #heroText h3{
    font-size: 18px;
  }

  #heroText p{
    font-size: 16px;
  }

  #heroText {
    padding: 5px;
  }

  #container {
    padding: 10px 10px;
  }
  
  #social_icons {
    display: inline-flex;
    flex-wrap: wrap;
  }

  #career-li-dets > p {
    width: 80%;
    text-align: center;
    margin: auto;
  }

  #education_tile {
    width: 90%;
    margin: auto;
  }
}


/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: var(--yellow);
  -webkit-box-shadow: inset 6px 6px 12px var(--backgroundShadow), inset -6px -6px 12px var(--backgroundForeshadow), 0 0 0px 1000px var(--backgroundColor) inset;
  box-shadow: inset 6px 6px 12px var(--backgroundShadow), inset -6px -6px 12px var(--backgroundForeshadow), 0 0 0px 1000px var(--backgroundColor) inset;
}